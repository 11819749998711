<template>
	<div class="h-100">
		<CCard class="h-100">
			<CCardBody class="technician-form">
				<div v-if="!isUpdate" class="shop__header">
					<h5 class="mb-3">
						{{ $t("EmailsTitle.CreateEmail") }}
					</h5>
				</div>
				<div v-else class="shop__header">
					<h5 class="mb-3">
						{{ getTitle }}
					</h5>
				</div>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitForm)">
						<div class="custom">
							<div class="custom__input">
								<!-- ID  -->
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="email_ID" mode="lazy">
										<div class="form-group row">
											<label for="email_ID" class="col-md-2 col-12 col-form-label">{{
												$t("EmailFormFields.ID")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="email_ID"
													v-model.trim="templateForm.templateId"
													type="text"
													class="form-control"
													:placeholder="$t('EmailFormFields.ID')"
													autocomplete="email_ID"
													:maxlength="TEAMPALTE_RULE.ID"
													disabled
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- email title  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="email_title"
										:rules="`max:${TEAMPALTE_RULE.TITLE}|requiredExport`"
									>
										<div class="form-group required row">
											<label for="email_title" class="col-md-2 col-12 col-form-label">{{
												$t("EmailFormFields.Title")
											}}</label>
											<div :class="['col-12 col-md-10', classes]">
												<div class="d-flex">
													<textarea
														id="email_title"
														ref="emailTitle"
														v-model.trim="templateForm.title"
														type="text"
														rows="2"
														class="form-control remove-resize"
														:placeholder="$t('EmailFormFields.Title')"
														autocomplete="email_title"
														:maxlength="TEAMPALTE_RULE.TITLE"
													/>
													<div class="actions">
														<button
															type="button"
															class="btn btn-outline-custom ml-2 px-4 w-100 mb-2"
															@click="setTitleEmail(TEXT_INSERT.ORDER_TITLE)"
														>
															{{ $t("Button.OrderTitle") }}
														</button>
														<button
															class="px-4 ml-2 btn btn-outline-custom w-100"
															type="button"
															@click="setTitleEmail(TEXT_INSERT.ORDER_ID)"
														>
															{{ $t("Button.OrderId") }}
														</button>
													</div>
												</div>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- email description  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="email_desciption"
										:rules="`requiredExport|max:${TEAMPALTE_RULE.DESCRIPTION}`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="email_desciption" class="col-md-2 col-12 col-form-label">{{
												$t("EmailFormFields.Description")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<input
													id="email_desciption"
													v-model.trim="templateForm.description"
													type="text"
													class="form-control"
													:placeholder="$t('EmailFormFields.Description')"
													autocomplete="email_desciption"
													:maxlength="TEAMPALTE_RULE.DESCRIPTION"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>

						<CRow class="text-center">
							<CCol>
								<button type="button" class="btn btn-outline-danger" @click="cancel">
									{{ $t("Button.Cancel") }}
								</button>
								<button class="px-4 ml-2 btn btn-outline-custom" type="submit">
									{{ isUpdate ? $t("Button.Update") : $t("Button.Create") }}
								</button>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { TEAMPALTE_RULE, TEXT_INSERT } from "@/shared/plugins/constants"
import { createNamespacedHelpers } from "vuex"
const { mapState } = createNamespacedHelpers("email")
export default {
	name: "TempEmailForm",
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			TEAMPALTE_RULE,
			TEXT_INSERT,
			templateForm: {
				title: null,
				description: null,
				templateId: null,
			},
		}
	},
	computed: {
		...mapState(["isErr", "tempEmailDetail", "totalItems", "tempLast"]),
		getTitle() {
			return `${this.id} ${this.$t("EmailsTitle.UpdateEmail")}`
		},
	},
	watch: {
		tempEmailDetail() {
			this.setUpData()
		},
		tempLast() {
			this.getIdTemplate()
		},
	},
	created() {
		this.setUpData()
		if (this.tempLast) {
			this.getIdTemplate()
		} else {
			this.templateForm.templateId = "TEMP01"
		}
	},
	methods: {
		submitForm: async function () {
			const params = {
				...this.templateForm,
			}
			if (this.isUpdate) {
				delete params.templateId
				delete params.createdDate
				params["id"] = this.templateForm.templateId
			}

			this.$emit("submit", params)
		},
		cancel: function () {
			this.$emit("cancel")
		},
		setUpData() {
			if (this.isUpdate && this.tempEmailDetail) {
				this.templateForm = { ...this.tempEmailDetail, templateId: this.tempEmailDetail.id }
			}
		},
		getIdTemplate() {
			const idLast = parseInt(this.tempLast.id.split("TEMP")[1])

			if (idLast < 9) {
				this.templateForm.templateId = `TEMP0${idLast + 1}`
			} else {
				this.templateForm.templateId = `TEMP${idLast + 1}`
			}
		},
		setTitleEmail(text) {
			if (!this.templateForm.title) {
				return (this.templateForm.title = text)
			}
			const emailTitle = this.$refs.emailTitle
			const cursorPosition = emailTitle.selectionStart
			const newTitle =
				this.templateForm.title.slice(0, cursorPosition) +
				text +
				this.templateForm.title.slice(cursorPosition)
			emailTitle.focus()
			return (this.templateForm.title = newTitle)
		},
	},
}
</script>

<style lang="scss" scoped>
.custom__input {
	width: 100%;
}
</style>
