var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('CCard',{staticClass:"h-100"},[_c('CCardBody',{staticClass:"technician-form"},[(!_vm.isUpdate)?_c('div',{staticClass:"shop__header"},[_c('h5',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("EmailsTitle.CreateEmail"))+" ")])]):_c('div',{staticClass:"shop__header"},[_c('h5',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.getTitle)+" ")])]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('div',{staticClass:"custom"},[_c('div',{staticClass:"custom__input"},[_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"email_ID","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"email_ID"}},[_vm._v(_vm._s(_vm.$t("EmailFormFields.ID")))]),_c('div',{class:['col-md-10 col-12', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.templateForm.templateId),expression:"templateForm.templateId",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"email_ID","type":"text","placeholder":_vm.$t('EmailFormFields.ID'),"autocomplete":"email_ID","maxlength":_vm.TEAMPALTE_RULE.ID,"disabled":""},domProps:{"value":(_vm.templateForm.templateId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.templateForm, "templateId", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"email_title","rules":("max:" + (_vm.TEAMPALTE_RULE.TITLE) + "|requiredExport")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"email_title"}},[_vm._v(_vm._s(_vm.$t("EmailFormFields.Title")))]),_c('div',{class:['col-12 col-md-10', classes]},[_c('div',{staticClass:"d-flex"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.templateForm.title),expression:"templateForm.title",modifiers:{"trim":true}}],ref:"emailTitle",staticClass:"form-control remove-resize",attrs:{"id":"email_title","type":"text","rows":"2","placeholder":_vm.$t('EmailFormFields.Title'),"autocomplete":"email_title","maxlength":_vm.TEAMPALTE_RULE.TITLE},domProps:{"value":(_vm.templateForm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.templateForm, "title", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('button',{staticClass:"btn btn-outline-custom ml-2 px-4 w-100 mb-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.setTitleEmail(_vm.TEXT_INSERT.ORDER_TITLE)}}},[_vm._v(" "+_vm._s(_vm.$t("Button.OrderTitle"))+" ")]),_c('button',{staticClass:"px-4 ml-2 btn btn-outline-custom w-100",attrs:{"type":"button"},on:{"click":function($event){return _vm.setTitleEmail(_vm.TEXT_INSERT.ORDER_ID)}}},[_vm._v(" "+_vm._s(_vm.$t("Button.OrderId"))+" ")])])]),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1),_c('div',{staticClass:"form-group position-relative"},[_c('ValidationProvider',{attrs:{"name":"email_desciption","rules":("requiredExport|max:" + (_vm.TEAMPALTE_RULE.DESCRIPTION)),"mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group required row"},[_c('label',{staticClass:"col-md-2 col-12 col-form-label",attrs:{"for":"email_desciption"}},[_vm._v(_vm._s(_vm.$t("EmailFormFields.Description")))]),_c('div',{class:['col-md-10 col-12', classes]},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.templateForm.description),expression:"templateForm.description",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"id":"email_desciption","type":"text","placeholder":_vm.$t('EmailFormFields.Description'),"autocomplete":"email_desciption","maxlength":_vm.TEAMPALTE_RULE.DESCRIPTION},domProps:{"value":(_vm.templateForm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.templateForm, "description", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"error-mess"},[_vm._v(" "+_vm._s(errors[0])+" ")])])])]}}],null,true)})],1)])]),_c('CRow',{staticClass:"text-center"},[_c('CCol',[_c('button',{staticClass:"btn btn-outline-danger",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("Button.Cancel"))+" ")]),_c('button',{staticClass:"px-4 ml-2 btn btn-outline-custom",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.isUpdate ? _vm.$t("Button.Update") : _vm.$t("Button.Create"))+" ")])])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }